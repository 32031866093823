<template>
	<div>
		<div class="tw-mt-3 tw-mb-6">
			<div class="overflow-auto tw-mt-3 tw-max-h-40" style="min-height: 100px;">
				<div class="table-ui-detail">
					<table>
						<thead>
							<tr>
								<th class="tw-text-center" width="7%">
									{{ $t("employee.conStartdate") }}
								</th>
								<th class="tw-text-center" width="5%">
									{{ $t("employee.conPeriod") }}
								</th>
								<th class="tw-text-center" width="7%">
									{{ $t("employee.conExpiredate") }}
								</th>
								<th class="tw-text-center" width="11%">
									{{ $t("employee.guarantorName") }}
								</th>
								<th class="tw-text-center" width="4%">
									{{ $t("employee.sex") }}
								</th>
								<th class="tw-text-center" width="9%">
									{{ $t("employee.phoneNumber") }}
								</th>
								<th class="tw-text-center" width="7%">
									{{ $t("employee.ssnNo") }}
								</th>
								<th class="tw-text-center" width="8%">
									{{
										$t("employee.nationality")
									}}
								</th>
								<th class="tw-text-center tw-no-wrap" width="34%">
									{{
										$t("employee.address")
									}}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(guarantor, index) in items" :key="index">
								<td class="tw-text-center">
									{{ guarantor.contract_start_date }}
								</td>
								<td class="tw-text-center">
									{{ guarantor.contract_period }}
								</td>
								<td class="tw-text-center">
									{{ guarantor.contract_expire_date }}
								</td>
								<td class="tw-flex-wrap">
									{{ guarantor.guarantor_name }}
								</td>
								<td class="tw-text-center">
									{{ guarantor.guarantor_sex }}
								</td>
								<td>
									{{ guarantor.phone_number }}
								</td>
								<td>
									{{ guarantor.guarantor_nid_number }}
								</td>
								<td>
									{{ guarantor.nationality_name_en }}
								</td>
								<td>
									{{ guarantor.address }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "guarantor-info",
	props: ["value"],
	data() {
		return {
			model: {},
		};
	},
	computed: {
		items() {
			return this.model.employment_guarantor;
		}
	},
	created() {
		this.model = this.value;
	},
	methods: {},
};
</script>
